<template>
  <div class="dark:text-gray-10 max-h-full px-16 pt-16 text-gray-900">
    <div class="cursor-pointer" @click="goBack">
      <company-logo />
    </div>

    <div v-t="'layout.terms.termsOfServiceTitle'" class="text-34 mt-32 font-semibold" />
    <slot />
  </div>
</template>

<script setup lang="ts">
const goBack = async () => navigateTo('/');
</script>
